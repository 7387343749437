import { template as template_2085f0b629dd4fc8b0ae34264283c378 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2085f0b629dd4fc8b0ae34264283c378(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
