import { template as template_b5bc6820023b4937abde399bad6bc503 } from "@ember/template-compiler";
const FKLabel = template_b5bc6820023b4937abde399bad6bc503(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
